import React, {useEffect} from 'react';
import Layout from '../components/Layout'
import Slide from '../components/Slideshow/Slide'
import Blog from '../components/Home/Blog'
import Testimonials from '../components/Home/Testimonials';
import Link from 'next/link'
import Footer from "../components/Footer";
import Benefits from "../components/Home/Benefits";
import Services from "../components/Home/Services";
import Maintenance from "../components/Home/Maintenance";
import Faqs from "../components/Home/Faqs";
import Plans from "../components/Home/Plans";
import Clients from "../components/Home/Clients";

export default function Home() {
    return (
        <Layout
            title="Desarrollo Web Profesional"
            metaTitle="Desarrollo Web Profesional a Medida"
            metaDescription="Desarrollo Web Profesional: Sitios web, tiendas online y aplicaciones web. Expertos en WordPress, Prestashop, Laravel y Next.js"
        >
            <div className="webapp mx-auto">
                <Slide/>

                <div className="container">
                    <div className="content">
                        <Services />

                        <Clients />

                        <Maintenance />

                        <Benefits />

                        <Testimonials />

                        <Faqs />

                        <Plans />
                    </div>
                </div>
            </div>
        </Layout>
    )
}
